<script setup>
import FormElementMixins from '@/mixins/FormElementMixins'
</script>
<template lang="">
    <div>
        <div class="mb-3">
            <small class="text-muted fw-bold">{{ element.type }}</small>
            <div class="row bg-light border py-3 px-2 gx-1">
                <div class="col-4">
                    <input type="text" v-model="element.title" placeholder="title" class="form-control">
                </div>
                <!-- <div class="col-2">
                    <input type="text" v-model="element.placeholder" placeholder="placeholder" class="form-control">
                </div> -->
                <!-- <div class="col">
                    <div class="form-check h-100">
                        <input type="checkbox" :id="`${element.name}-required`" v-model="element.required" class="form-check-input">
                        <label class="form-check-label" :for="`${element.name}-required`">Required</label>
                    </div>
                </div> -->
                <div class="col-auto">
                    <Popper-X arrow>
                        <span class="text-primary mx-2 fs-5" :class="{'text-muted': !element.help}">
                            <BIconQuestionSquareFill></BIconQuestionSquareFill>
                        </span>
                        <template v-slot:content>
                            <textarea v-model="element.help" rows="10" class="form-control" placeholder="Help text"></textarea>
                        </template>
                    </Popper-X>
                </div>
                <div class="col-auto">
                    <input type="number" v-model="element.columns" placeholder="6" min="1" max="12" class="form-control font-monospace text-center">
                </div>
                <div class="col-auto" v-if="advEdits">
                    <AdminFormElementButtonRemove :element="element" @remove-element="(args) => $emit('removeElement', args)"></AdminFormElementButtonRemove>
                </div>
            </div>
            <!-- <label :for="fieldId" class="form-label" v-if="fieldTitle">{{ fieldTitle }} 
            </label>
                
            <input :data-section="element.section" v-model="element.value" type="text" class="form-control" :id="fieldId" :key="fieldId" :placeholder="element.placeholder"> -->
        </div>
    </div>
</template>
<script>
export default {
    name: 'AdminFormElementFile',
    mixins: [FormElementMixins]
}
</script>
<style lang="scss">
    :root {
    --popper-theme-background-color: #F6F6F6;
    --popper-theme-background-color-hover: #F6F6F6;
    --popper-theme-text-color: #000;
    --popper-theme-border-width: 1px;
    --popper-theme-border-style: solid;
    --popper-theme-border-color: #D9D9D9;
    --popper-theme-border-radius: 4px;
    --popper-theme-padding: 24px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.05);
    
  }
</style>