<script setup>
import { defineProps } from 'vue'
const props = defineProps(['form'])

</script>
<template>
    <div class="container bg-secondary border border-top-0 px-4 py-4"> 
        <div class="row">
            <div class="col-12">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" @change="$emit('on-advedits-changed', $event)" :checked="props.form.preferences.advancedEdits">
                    <label class="form-check-label" for="flexSwitchCheckChecked">Adv. edits</label>
                </div>
            </div> 
        </div> 
    </div>
</template>


<style lang="scss" scoped>

</style>