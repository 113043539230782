<script setup>
</script>
<template>
    <div :class="{
        'h-100 d-flex align-items-center': !wrapperClass,
        [wrapperClass]: wrapperClass
    }">
        <button type="button" @click="addElement" :class="{
            'btn btn-success fs-3 d-flex align-items-center px-2': !btnClass,
            [btnClass]: btnClass
        }"><BIconPlusLg></BIconPlusLg></button>
    </div>
</template>

<script>
    export default {
        name: 'AdminFormElementButtonAdd',
        methods: {
            addElement() {
                this.$emit('addElement', this.element)
                
            }
        },
        props: {
            element: {
                type: Object,
                required: true
            },
            wrapperClass: {
                type: String
            },
            btnClass: {
                type: String
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>