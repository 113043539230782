<script setup>
import FormElementMixins from '@/mixins/FormElementMixins'
import FormElement from './FormElement.vue';
import { Collapse } from 'vue-collapsed'
</script>
<template lang="">
    <div>
        <Collapse :when="!iscollapsed">
            <div class="mb-3" :class="{
                'mb-3': !element.class,
                [element.class]: element.class
                }">
                <label :for="fieldId" class="form-label" v-if="fieldTitle">{{ fieldTitle }} 
                </label>
                    <Popper-X v-if="element.help" arrow>
                    <span class="text-primary mx-2 fs-5">
                        <BIconQuestionSquareFill></BIconQuestionSquareFill>
                    </span>
                    <template v-slot:content>
                        <div v-html="element.help"></div>
                    </template>
                    </Popper-X>
                
                <div class="row">
                    <div :class="`col-${el.columns}`" v-for="(el,i) in element.elements" :key="`${el.name}-${i}`">

                        <FormElement 
                                :iscollapsed="el.collapsed" 
                                :element="el" 
                                @select-changed="(args) => $emit('selectChanged', args)" 
                                @checkbox-changed="(args) => $emit('checkboxChanged', args)" 
                                @radio-changed="(args) => $emit('radioChanged', args)" 
                                :key="`sub-${el.name}-${i}`"
                                :index="index"
                            />
                    </div>
                </div>
            </div>
        </Collapse>
    </div>
</template>
<script>
export default {
    name: 'FormElementMulti',
    mixins: [FormElementMixins]
}
</script>
<style lang="scss">
    
</style>