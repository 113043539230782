<script setup>
    import SubmissionHeader from '@/components/submission/SubmissionHeader.vue'
    import SubmissionContent from '@/components/submission/SubmissionContent.vue'
    import ApiService from '@/services/ApiService'
</script>
<template lang="">
    <div v-if="submission">
            <div>
                <SubmissionHeader :submission="submission"></SubmissionHeader>
                <SubmissionContent :submission="submission"></SubmissionContent>
            </div>
    </div>
</template>
<script>
    export default {
        name: 'SubmissionView',
        components: {
            SubmissionHeader,
            SubmissionContent
        },
        data() {
            return {
                submission: null,
            }
        },
        methods: {
            fetchSubmission(id) {
                ApiService.getSubmission(id)
                .then(response => {
                    this.submission = response.data
                })
                .catch(error => {
                    console.log(error)
                })
            }  
        },
        mounted() {
            this.fetchSubmission(this.$route.params.submissionid)
        }
    }
</script>
<style lang="">

</style>