<script setup>
</script>
<template>
    <div :class="{
        [wrapperClass]: wrapperClass
    }">
        <button type="button" @click="removeElement" :class="{
            'btn btn-danger d-block': !btnClass,
            [btnClass]: btnClass
        }"><BIconTrash></BIconTrash></button>
    </div>
</template>

<script>
    export default {
        name: 'AdminFormElementButtonRemove',
        methods: {
            removeElement() {
                this.confirm(() => {
                    this.$emit('removeElement', this.element)
                },
                () => {
                    console.log('cancel')
                })
            },
            confirm(no=(()=>false), yes=(()=>false), title="Are you sure?" ,message="This action cannot be undone.") {
                this.$confirm({
                    title,
                    message,
                    button: {
                        no: "Ja",
                        yes: "Nej",
                    },
                    callback: confirm => {
                        if (confirm) {
                            yes()
                        } else {
                            no()
                        }
                    }
                })
            }
        },
        props: {
            element: {
                type: Object,
                required: true
            },
            wrapperClass: {
                type: String
            },
            btnClass: {
                type: String
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>