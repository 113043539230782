import axios from 'axios';
import router from '../router'
const apiClient = axios.create({
    baseURL: process.env.VUE_APP_DBHOST,
    withCredentials: false,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    timeout: 20000
})

export default {
  handleResponse(response, push) {
      this.alert = {
          variant: response.status == 200 ? 'success' : 'danger',
          message: response.message
      }
      if ( push ) {
          router.push(push)
      }
  },
    getForms() {
        return apiClient.get(`/api/form`)
    },
    getForm(id) {
        return apiClient.get(`/api/form/${id}`)
    },
    removeForm(id) {
        return apiClient.delete(`/api/form/${id}`, {
          headers: { 
            'x-Auth-Token': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : '',
            'admin': true
           }
        })
    },
    createForm(payload) {
        return apiClient.post(`/api/form`, payload, {
          headers: { 
            'x-Auth-Token': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : '',
            'admin': true
           }
        })
    },
    getTables() {
        return apiClient.get(`/api/table`)
    },
    getTable(id) {
        return apiClient.get(`/api/table/${id}`)
    },
    getFormElements() {
        return apiClient.get('/api/form-element')
    },
    getSubmission(id) {
        return apiClient.get(`/api/submission/${id}`)
    },
    uploadFiles(id, formData) {
      return apiClient.post(`/api/upload/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },
    saveForm(id, formData) {
      return apiClient.put(`/api/form/${id}`, formData, {
        headers: { 
          'x-Auth-Token': JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : '',
          'admin': true
         }
      })
    },
    submitForm(formData) {
      return apiClient.post(`/api/submission`, formData)
    },
    sendEmail(id) {
      return apiClient.post(`/api/submission/${id}`)
    },
    authUser(email, password) {
      return apiClient.post('/api/auth', { email, password })
    }
}