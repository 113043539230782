<script setup>
import FormElementMixins from '@/mixins/FormElementMixins'

</script>
<template lang="">
    <div>
        <div class="mb-3">
            <div class="form-check">
                <input :disabled="element.disabled" :data-set="JSON.stringify(element.set)" :data-section="element.section" :id="`field${element.name}-${element.value}`" :name="element.name" v-model="element.selected" class="form-check-input" type="radio" :checked="element.checked" :value="element.value" @change="$emit('radioChanged', $event)">
                <label class="form-check-label" :for="`field${element.name}-${element.value}`" v-if="fieldTitle">
                    {{ fieldTitle }}
                </label>
                <Popper-X v-if="element.help" arrow>
                    <span class="text-primary mx-2 fs-5">
                        <BIconQuestionSquareFill></BIconQuestionSquareFill>
                    </span>
                    <template v-slot:content>
                        <div v-html="element.help"></div>
                    </template>
                </Popper-X>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'FormElementRadio',
    mixins: [FormElementMixins],
    updated() {
        if ( this.element.checked == true ) {
            console.log('Trigger change on radio')
            this.$emit('radioChanged', { target: { 
                value: this.element.value,
                name: this.element.name,
                checked: this.element.checked,
                dataset: {
                    section: this.element.section,
                    set: this.element.set
                }
            } })
        }
    },
}
</script>
<style lang="">
    
</style>