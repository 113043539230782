import AdminFormElementButtonRemove from '@/components/admin/elements/buttons/AdminFormElementButtonRemove'
import SelectElement from '@/components/global/SelectElement.vue'
import { useAdminPreferences } from '@/composables/admin-preferences.js'
const { preferences } = useAdminPreferences()
export default {
    components: {
        AdminFormElementButtonRemove,
        SelectElement
    },
    data() {
        return {
            elementTypes: [
                "text", 
                "textarea", 
                "select", 
                "checkbox", 
                "radio", 
                "file", 
                "number", 
                "email", 
                "tel"
            ]
        }
    },
    computed: {
        elementTypesOptions() {
            return this.elementTypes.map(type => {
                return {
                    value: type,
                    text: type.charAt(0).toUpperCase() + type.slice(1)
                }
            })
        },
        requiredMark() {
            return this.element.required ? '*' : ''
        },
        fieldTitle() {
            if ( this.title == "" ) {
                return ""
            }
            if ( this.title ) {
                return this.title !== "" ? this.title + this.requiredMark : ""
            }
            return this.element.title !== "" ? this.element.title + this.requiredMark : ""
        },
        fieldValue: {
            get() {
                return this.element.value
            },
            set(value) {
                console.log('set', value)
                this.element.value = value
                // this.$emit('input', value)
            }
        },
        fieldIndex() {
            if ( !isNaN(this.index) ) {
                return "-" + this.index
            }
            return ""
        },
        fieldId() {
            return `field-${this.element.type}-${this.element.name}${this.fieldIndex}`
        },
        advEdits() {
            return preferences.advancedEdits
        }
    },
    props: {
        element: {
            type: Object,
            required: true
        },
        title: {
            type: String,
            required: false
        },
        value: {
            type: [String, Number, Boolean],
            required: false
        },
        index: {
            type: Number,
            required: false
        },
        key: {
            type: String,
            required: true
        },
        iscollapsed: {
            type: Boolean,
            default: false
        }
    }
}