<script setup>
import FormElementMixins from '@/mixins/FormElementMixins'
import ApiService from '@/services/ApiService';
</script>
<template lang="">
    <div>
        <mark class="position-absolute font-monospace px-2" v-if="table">{{table.id}}</mark>
        <div class="">
            <small class="text-muted fw-bold">{{ element.type }}</small>
            <div class="row bg-light border py-2 px-1 gx-1">
                <div class="col-12">
                    <select class="form-select" v-model="selectedId">
                        <option v-for="(option,i) in tables" :value="option._id" :key="`${option.value}-${i}`">{{ option.title }}</option>
                    </select>
                </div>
            </div>
            <!-- <label :for="fieldId" class="form-label" v-if="fieldTitle">{{ fieldTitle }} 
            </label>
                
            <input :data-section="element.section" v-model="element.value" type="text" class="form-control" :id="fieldId" :key="fieldId" :placeholder="element.placeholder"> -->
        </div>
    </div>
</template>
<script>
export default {
    mixins: [FormElementMixins],
    data() {
        return {
            tables: [],
            table: null
        }
    },
    computed: {
        selectedId() {
            return this.table ? this.table._id : null
        }
    },
    components: {
    },
    methods: {
        async fetchTables() {
            await ApiService.getTables().then((response) => {
                this.tables = response.data
            })
        },
        async fetchTable() {
            await ApiService.getTable(this.element.tid).then((response) => {
                this.table = response.data
            })
        }
    },
    mounted() {
        this.fetchTables()
        this.fetchTable()
    }
}
</script>
<style lang="scss">
    :root {
    --popper-theme-background-color: #F6F6F6;
    --popper-theme-background-color-hover: #F6F6F6;
    --popper-theme-text-color: #000;
    --popper-theme-border-width: 1px;
    --popper-theme-border-style: solid;
    --popper-theme-border-color: #D9D9D9;
    --popper-theme-border-radius: 4px;
    --popper-theme-padding: 24px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.05);
    
  }
</style>