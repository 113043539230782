<script setup>
import FormElementMixins from '@/mixins/FormElementMixins'
</script>
<template lang="">
    <div>
        <div class="mb-3">
            <small class="text-muted fw-bold">{{ element.type }}</small>
            <div class="row bg-light border py-3 px-2 gx-1">
                <div class="col-4">
                    <input type="text" v-model="element.title" placeholder="title" class="form-control">
                </div>
                <!-- <div class="col-auto" v-if="advEdits">
                    <input type="text" v-model="element.name" placeholder="name" class="form-control font-monospace">
                </div> -->
                <div class="col-2">
                    <input type="text" v-model="element.placeholder" placeholder="placeholder" class="form-control">
                </div>
                <div class="col-4" v-if="advEdits">
                    <SelectElement :options="elementTypesOptions" v-model:value="element.type"></SelectElement>
                </div>
                <div class="col-auto px-2">
                    <div class="form-check h-100 d-flex align-items-center">
                        <input type="checkbox" :id="`${element.name}-required`" v-model="element.required" class="form-check-input">
                        <label class="form-check-label mx-1" :for="`${element.name}-required`">Required</label>
                    </div>
                </div>
                <div class="col-auto">
                    <Popper-X arrow>
                        <span class="text-primary mx-2 fs-5" :class="{'text-muted': !element.help}">
                            <BIconQuestionSquareFill></BIconQuestionSquareFill>
                        </span>
                        <template v-slot:content>
                            <textarea v-model="element.help" rows="10" class="form-control" placeholder="Help text"></textarea>
                        </template>
                    </Popper-X>
                </div>
                <div class="col-auto" v-if="advEdits">
                    <input type="number" v-model="element.columns" placeholder="6" min="1" max="12" class="form-control font-monospace text-center">
                </div>
                <div v-if="advEdits">
                    <div class="col-12 mt-2" v-for="(opt, index) in element.options" :key="`${element.name}-options${index}`">
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Title" v-model="opt.title">
                            <input type="text" class="form-control" placeholder="Value" v-model="opt.value">
                            <input v-if="advEdits" type="text" class="form-control bs-highlight font-monospace" placeholder="Target section" v-model="opt.section">
                            <button type="button" class="btn btn-danger mt-2" @click="removeOption(index)"><BIconDashLg></BIconDashLg></button>
                        </div>
                    </div>
                </div>
                <button v-if="advEdits" type="button" class="btn btn-primary mt-2" @click="addOption()"><BIconPlusLg></BIconPlusLg> Add option</button>
            </div>
            <!-- <label :for="fieldId" class="form-label" v-if="fieldTitle">{{ fieldTitle }} 
            </label>
                
            <input :data-section="element.section" v-model="element.value" type="text" class="form-control" :id="fieldId" :key="fieldId" :placeholder="element.placeholder"> -->
        </div>
    </div>
</template>
<script>
export default {
    name: 'AdminFormElementSelect',
    mixins: [FormElementMixins],
    computed: {
        optionsText: {
            get() {
                return this.options ? this.options.join('\n').map(opt => {
                    return `${opt.title}|${opt.value}|${opt.section}`
                }) : ''
            },
            set(value) {
                this.options = value.split('\n').map(opt => {
                    const [title, value, section] = opt.split('|')
                    return { title, value, section }
                })
            }
        }
    },
    methods: {
        addOption() {
            if ( !this.element.options ) this.element.options = []
            this.element.options.push({ title: '', value: '', section: '' })
        },
        removeOption(index) {
            this.element.options.splice(index, 1)
        }
    }
}
</script>
<style lang="scss">
    
</style>