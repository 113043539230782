<script setup>
import FormElementMixins from '@/mixins/FormElementMixins'
import FormElementText from '@/components/form/FormElementText.vue'
import FormElementNumber from '@/components/form/FormElementNumber.vue'
import FormElementSelect from '@/components/form/FormElementSelect.vue'
import FormElementSection from '@/components/form/FormElementSection.vue'
import FormElementTable from '@/components/form/FormElementTable.vue'
import FormElementCheckbox from '@/components/form/FormElementCheckbox.vue'
import FormElementRadio from '@/components/form/FormElementRadio.vue'
import FormElementTextarea from '@/components/form/FormElementTextarea.vue'
import FormElementEmail from '@/components/form/FormElementEmail.vue'
import FormElementTel from '@/components/form/FormElementTel.vue'
import FormElementFile from '@/components/form/FormElementFile.vue'
import FormElementMulti from '@/components/form/FormElementMulti.vue'
// import FormElementTableref from '@/components/form/_FormElementTableref.vue'
</script>
<template lang="">
    <div>
        <template v-if="element.type == 'text'">
            <FormElementText :title="title" :index="index" :element="element" />
        </template>
        <template v-else-if="element.type == 'number'">
            <FormElementNumber :title="title" :index="index" :element="element" />
        </template>
        <template v-else-if="element.type == 'select'">
            <FormElementSelect :iscollapsed="iscollapsed" :title="title" :index="index" :element="element" @select-changed="(args) => $emit('selectChanged', args)" />
        </template>
        <template v-else-if="element.type == 'section'">
            <FormElementSection :iscollapsed="iscollapsed" :title="title" :index="index" :element="element" @select-changed="(args) => $emit('selectChanged', args)" @checkbox-changed="(args) => $emit('checkboxChanged', args)" @radio-changed="(args) => $emit('radioChanged', args)" @on-files-updated="(args) => $emit('on-files-updated', args)" />
        </template>
        <template v-else-if="element.type == 'table'">
            <FormElementTable :iscollapsed="iscollapsed" :title="title" :index="index" :element="element" @select-changed="(args) => $emit('selectChanged', args)" />
        </template>
        <template v-else-if="element.type == 'multi'">
            <FormElementMulti :iscollapsed="iscollapsed" :title="title" :index="index" :element="element" @select-changed="(args) => $emit('selectChanged', args)" @checkbox-changed="(args) => $emit('checkboxChanged', args)" @radio-changed="(args) => $emit('radioChanged', args)" />
        </template>
        <template v-else-if="element.type == 'checkbox'">
            <FormElementCheckbox :title="title" :index="index" :element="element" @checkbox-changed="(args) => $emit('checkboxChanged', args)" />
        </template>
        <template v-else-if="element.type == 'radio'">
            <FormElementRadio :title="title" :index="index" :element="element" @radio-changed="(args) => $emit('radioChanged', args)" />
        </template>
        <template v-else-if="element.type == 'textarea'">
            <FormElementTextarea :title="title" :index="index" :element="element" />
        </template>
        <template v-else-if="element.type == 'email'">
            <FormElementEmail :title="title" :index="index" :element="element" />
        </template>
        <template v-else-if="element.type == 'tel'">
            <FormElementTel :title="title" :index="index" :element="element" />
        </template>
        <template v-else-if="element.type == 'file'">
            <FormElementFile :title="title" :index="index" :element="element" @on-files-updated="(args) => $emit('on-files-updated', args)" />
        </template>
        <template v-else-if="element.type == 'tableref'">
            <!-- <FormElementTableref :iscollapsed="iscollapsed" :title="title" :index="index" :element="element" /> -->
        </template>
        <template v-else>
            <div>
                Field type <mark>{{element.type}}</mark> does not exist
            </div>
        </template>
    </div>
</template>
<script>
export default {
    name: 'FormElement',
    mixins: [FormElementMixins],
    
    components: {
        FormElementText,
        FormElementNumber,
        FormElementSelect,
        FormElementSection,
        FormElementTable,
        FormElementCheckbox,
        FormElementTextarea,
        FormElementEmail,
        FormElementTel,
        FormElementFile,
        FormElementMulti,
        // FormElementTableref
    }
}
</script>
<style lang="scss">

:root {
    --popper-theme-background-color: #F6F6F6;
    --popper-theme-background-color-hover: #F6F6F6;
    --popper-theme-text-color: #000;
    --popper-theme-border-width: 1px;
    --popper-theme-border-style: solid;
    --popper-theme-border-color: #D9D9D9;
    --popper-theme-border-radius: 4px;
    --popper-theme-padding: 24px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.05);
  }
  .popper {
    min-width: auto !important;
    width: auto !important;
  }

</style>