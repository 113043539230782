<template lang="">
    <div class="container bg-primary text-white px-5 py-4" v-if="submission">
        <div class="d-flex align-items-center justify-content-between">
            <div class="w-auto">
                <h1 class="" v-if="submission.title">
                    {{submission.title}}
                </h1>
                <small>{{submission.form}}</small>
                <h5 v-if="submission.date">
                    Dato: {{ new Date(submission.date).toLocaleDateString() }}
                </h5>
            </div>
            <div class="ms-5">
                <img class="float-end" alt="Assa Abloy logo" src="@/assets/assa-logo-white.svg"> 
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'SubmissionHeader',
    props: {
        submission: {
            type: Object,
            required: true
        }
    },
}
</script>
<style lang="">
    
</style>