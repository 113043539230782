<script setup>
    import AdminFormHeader from '@/components/admin/AdminFormHeader.vue'
    import AdminFormPreferences from '@/components/admin/AdminFormPreferences.vue'
    import AdminFormContent from '@/components/admin/AdminFormContent.vue';
    import ApiService from '@/services/ApiService'
    import { toRaw } from 'vue'
    import { useAdminPreferences } from '@/composables/admin-preferences.js'
    

</script>
<template>
    <div v-if="form" class="container">
        <form @submit="saveForm($event)" novalidate>
            <AdminFormHeader :form="form" @on-title-changed="updateTitle"></AdminFormHeader>

            <AdminFormPreferences :form="form" @on-advedits-changed="preferencesUpdated"></AdminFormPreferences>
            <AdminFormContent :form="form" :alert="alert" @add-root-element="addRootElement" @remove-root-element="removeRootElement"></AdminFormContent>
        </form>
    </div>
</template>

<script>
    var { updateAdvEdits } = useAdminPreferences()
    export default {
        name: 'AdminFormEdit',
        components: {
            AdminFormHeader,
            AdminFormContent,
            // draggable
        },
        data() {
            return {
                form: null,
                alert: null
            }
        },
        methods: {
            preferencesUpdated(event) {
                this.form.preferences.advancedEdits = event.target.checked
                updateAdvEdits(event.target.checked)
            },
            fetchForm(id) {
                ApiService.getForm(id)
                .then(response => {
                    this.form = response.data
                    updateAdvEdits(this.form.preferences.advancedEdits)
                })
                .catch(error => {
                    console.log(error)
                })
            },
            saveForm(e) {
                e.preventDefault();
                ApiService.saveForm(this.form._id, toRaw(this.form))
                .then(response => {
                    this.alert = {
                        variant: response.status == 200 ? 'success' : 'danger',
                        message: response.data.message
                    }
                    setTimeout(() => {
                        this.alert = null
                    }, 2000);
                })
                .catch(error => {
                    console.log(error)
                })
            },
            updateTitle(title) {
                this.form.title = title
            },
            closeAlert() {
                console.log('close alert')
                this.alert = null
            },
            addRootElement(afterIndex) {
                const newSection = {
                    title: '',
                    placeholder: 'New section',
                    body: '',
                    name: 'new-section',
                    collapsed: false,
                    type: 'section',
                    columns: 12,
                    elements: []
                }
                this.form.elements.splice(afterIndex + 1, 0, newSection)
            },
            removeRootElement(atIndex) {
                this.form.elements.splice(atIndex, 1)
            }
        },
        mounted() {
            this.fetchForm(this.$route.params.formid)
        }
    }
</script>

<style lang="scss" scoped>

</style>