<script setup>
    import { defineModel, defineProps } from 'vue'
    const value = defineModel('value', { type: String, required: false })
    defineProps({
        name: { type: String, required: true },
        options: { type: Array, required: true }
    })
</script>
<template>
    <div>
        <select class="form-select" :name="name" v-model="value">
            <option value=""></option>
            <option v-for="(o, i) in options" :value="o.value" :key="`${name}-${i}`">{{o.text}}</option>
        </select>
    </div>
</template>

<script>
    export default {
        name: 'SelectElement'
    }
</script>

<style lang="scss" scoped>

</style>