<script setup>
    import AdminFormHeader from '@/components/admin/AdminFormHeader.vue'
    import AdminFormsList from '@/components/admin/AdminFormsList.vue'
</script>
<template>
    <div class="container">
        <AdminFormHeader>
            <h1>Forms</h1>
        </AdminFormHeader>
        <AdminFormsList></AdminFormsList>
    </div>
</template>

<script>
    export default {
        name: 'AdminForms',
        components: {
            AdminFormHeader,
            AdminFormsList
        },
        data() {
            return {
            }
        },
        methods: {
        },
        mounted() {
        }
    }
</script>

<style lang="scss" scoped>

</style>