!<template>
    <div v-if="alert && message">
        <div :class="`alert alert-${variant || 'primary'} py-2 m-0`" role="alert">
            {{ message }}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AdminFormAlert',
        computed: {
            message() {
                return this.alert.message
            },
            variant() {
                return this.alert.variant
            }
        },
        props: {
            alert: {
                type: Object,
                required: false
            }
        },
        methods: {
            
        }
    }
</script>

<style lang="scss" scoped>
    .alert {
        text-align: center;
    }
</style>