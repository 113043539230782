<script setup>
import FormElement from '@/components/form/FormElement.vue'
import FormAlert from '@/components/form/FormAlert.vue'
import _ from 'lodash'
</script>
<template lang="">
        <div class="container bg-lighter border border-top-0 px-3 py-3 px-md-5 pt-md-5 pb-md-1">
        <div class="row">
            <div :class="`col-${el.columns || 6}`" v-for="(el, index) in form.elements" :key="index">
                <FormElement 
                    :iscollapsed="el.collapsed" 
                    :element="el" 
                    @checkbox-changed="(args) => $emit('checkboxChanged', args)" 
                    @radio-changed="(args) => $emit('radioChanged', args)" 
                    @select-changed="(args) => $emit('selectChanged', args)" 
                    @on-files-updated="(args) => $emit('on-files-updated', args)"
                    />
            </div>
        </div>

        <div class="sticky-bottom py-3 bg-light-subtle">
            <div class="row">
                <div class="col-12">
                    <button type="submit" class="btn btn-lg btn-primary py-2 d-block w-100 mb-3">Send</button>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <FormAlert :alert="alert"></FormAlert>
                </div>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-12">
                <button type="submit" class="btn btn-primary">Send</button>
            </div>
        </div> -->
    </div>
</template>
<script>
export default {
    name: 'FormContent',
    components: {
        FormElement,
        FormAlert
    },
    computed: {
        allCollapsedSections() {
            const elements = this.form.elements;
            return _.flatMapDeep(elements, (element) => {
                if (element.collapsed === true) {
                    return element.id;
                } else if (element.elements) {
                    return _.flatMapDeep(element.elements, (subElement) => {
                        if (subElement.collapsed === true) {
                            return subElement.id;
                        } else {
                            return [];
                        }
                    });
                } else {
                    return [];
                }
            });
        }
    },
    props: {
        form: {
            type: Object,
            required: true
        },
        alert: {
            type: Object,
            required: false
        }
    },
    methods: {
        confirm(no=(()=>false), yes=(()=>false), title="Are you sure?" ,message="This action cannot be undone.") {
            this.$confirm({
                title,
                message,
                button: {
                    no: "Ja",
                    yes: "Nej",
                },
                callback: confirm => {
                    if (confirm) {
                        yes()
                    } else {
                        no()
                    }
                }
            })
        }
    },
    created() {
        this.collapsedSections = this.allCollapsedSections
    },
}
</script>
<style lang="scss">

</style>