<script setup>
import FormElementMixins from '@/mixins/FormElementMixins'
// import { Collapse } from 'vue-collapsed'
</script>
<template lang="">
    <div>
        <!-- <Collapse :when="!iscollapsed"> -->
            <div class="mb-3" v-if="!iscollapsed">
                <label :for="`field${element.type}${index}`" class="form-label" v-if="fieldTitle">{{ fieldTitle }}:</label>
                <select class="form-select" :id="element.id" v-model="element.value" :name="element.name" :required="element.required" ref="select" @change="$emit('selectChanged', $event)">
                    <option value="" v-if="element.placeholder !== undefined" disabled>{{ element.placeholder }}</option>
                    <option :data-section="option.section" :data-element="element" :data-index="index" :data-children="JSON.stringify(option.children)" v-for="(option, idx) in element.options" :value="option.value" :key="`${element.options.value}-${index}-${idx}`">{{ option.title }}</option>
                </select>
                <div v-if="element.value == '_custom_'">
                    <input class="form-control mt-2" type="text" :name="`${element.name}-custom`" value="">
                </div>
            </div>
        <!-- </Collapse> -->
    </div>
</template>
<script>
export default {
    name: 'FormElementSelect',
    mixins: [FormElementMixins],
    mounted() {
        if (this.element.value) {
            this.$emit('selectChanged', { target: this.$refs.select })
        }
    },
}
</script>
<style lang="">
    
</style>