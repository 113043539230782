import { reactive } from 'vue'

const preferences = reactive({
    advancedEdits: true
})

export function useAdminPreferences() {
    

    function updateAdvEdits(on) {
        preferences.advancedEdits = on
    }

    return {
        preferences,
        updateAdvEdits
    }
    
    
}