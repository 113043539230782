<script setup>
import FormElementMixins from '@/mixins/FormElementMixins'
import FormElement from '@/components/form/FormElement.vue'
import _ from 'lodash'
import { Collapse } from 'vue-collapsed'
</script>
<template lang="">
    <div>
        <Collapse :when="!iscollapsed">
            <div class="table-responsive">
                <table class="table table-bordered table-light">
                    <thead class="table-primary">
                        <tr>
                            <th scope="col" v-for="(header, colidx) in element.headers" :colspan="header.colspan" :key="`thead1-${colidx}`">{{header.title}}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <thead class="thead-names">
                        <tr>
                            <th scope="col" v-for="(hcol, idx) in element.elements" :key="`thead2-${idx}`">{{hcol.title}}</th>
                            <th>{{hcol}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(row, i) in element.rows" :key="`row-${i}`">
                            <tr>
                                <td v-for="(col, index) in element.elements" :key="`col-${i}-${index}`">
                                    <FormElement
                                        v-if="row[index].collapsed === undefined || row[index].collapsed === false"
                                        :element="row[index]"
                                        @select-changed="(args) => $emit('selectChanged', args)"
                                        title=""
                                        :index="i"
                                        />
                                </td>
                                <td class="py-1">
                                    <a class="fs-3" @click="deleteRow(i)"><BIconTrash></BIconTrash></a>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                    <tfoot class="table-secondary">
                        <tr>
                            <td :colspan="element.elements.length + 1" class="p-0">
                                <button type="button" class="btn btn-primary float-end radius-0" @click="addRow()">+1 linje</button>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </Collapse>
    </div>
</template>
<script>
export default {
    name: 'FormElementTable',
    mixins: [FormElementMixins],
    components: {
        FormElement,
    },
    data() {
        return {
            rows: []
        }
    },
    methods: {
        addRow() {
            if (this.element.rows == undefined) {
                this.element.rows = []
            }
            const rowObj = _.map(this.element.elements, el => {
                let newObj = Object.assign({}, el)
                newObj.value = ''
                newObj.name = newObj.name + '-' + this.element.rows.length
                newObj.id = newObj.id + '-' + this.element.rows.length
                newObj.ref = newObj.ref + '-' + this.element.rows.length
                // Also handle elements if section
                if (newObj.elements) {
                    newObj.elements = _.map(newObj.elements, el => {
                        let newObj = Object.assign({}, el)
                        newObj.value = ''
                        newObj.name = newObj.name + '-' + this.element.rows.length
                        newObj.id = newObj.id + '-' + this.element.rows.length
                        newObj.ref = newObj.ref + '-' + this.element.rows.length
                        return newObj
                    })
                }
                return newObj
            })
            let newRow = rowObj
            this.element.rows.push(newRow)
        },
        deleteRow(i) {
            console.log('i', i)
            this.element.rows.splice(i, 1)
            if (this.element.rows.length == 0) {
                this.element.rows = []
                this.addRow()
            }
        }
    },
    mounted() {
        this.addRow() // Add single empty row
    }
}
</script>
<style lang="scss">
    @import '/scss/_colors';
    thead.table-primary th {
        background: $primary !important;
        color: #fff !important;
    }
    thead.thead-names th {
        font-size: 11px !important;
        font-weight: 300 !important;
        vertical-align: baseline;
    }
    tfoot button {
        border-radius: 0 !important;
    }
</style>