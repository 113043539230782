<script setup>
import FormElementMixins from '@/mixins/FormElementMixins'
</script>
<template lang="">
    <div>
        <div class="mb-3">
            <div class="form-check">
                <input :data-section="element.section" :data-set="element.set" :checked="element.checked" :id="`field${element.name}`" v-model="element.checked" class="form-check-input" type="checkbox" value="1" @change="$emit('checkboxChanged', {...element})">
                <label class="form-check-label" :for="`field${element.name}`" v-if="fieldTitle">
                    {{ fieldTitle }}
                </label>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'FormElementCheckbox',
    mixins: [FormElementMixins]
}
</script>
<style lang="">
    
</style>