<script setup>
import FormElementMixins from '@/mixins/FormElementMixins'
import AdminFormElementText from '@/components/admin/elements/AdminFormElementText.vue'
import AdminFormElementNumber from '@/components/admin/elements/AdminFormElementNumber.vue'
import AdminFormElementSelect from '@/components/admin/elements/AdminFormElementSelect.vue'
import AdminFormElementSection from '@/components/admin/elements/AdminFormElementSection.vue'
import AdminFormElementTable from '@/components/admin/elements/AdminFormElementTable.vue'
import AdminFormElementCheckbox from '@/components/admin/elements/AdminFormElementCheckbox.vue'
import AdminFormElementRadio from '@/components/admin/elements/AdminFormElementRadio.vue'
import AdminFormElementTextarea from '@/components/admin/elements/AdminFormElementTextarea.vue'
import AdminFormElementEmail from '@/components/admin/elements/AdminFormElementEmail.vue'
import AdminFormElementTel from '@/components/admin/elements/AdminFormElementTel.vue'
import AdminFormElementFile from '@/components/admin/elements/AdminFormElementFile.vue'
import AdminFormElementMulti from '@/components/admin/elements/AdminFormElementMulti.vue'
import AdminFormElementTableref from '@/components/admin/elements/AdminFormElementTableref.vue'
</script>
<template lang="">
    <div>
        <template v-if="element.type == 'text'">
            <AdminFormElementText :title="title" :index="index" :element="element" @remove-element="(args) => $emit('removeElement', args)" />
        </template>
        <template v-else-if="element.type == 'number'">
            <AdminFormElementNumber :title="title" :index="index" :element="element" @remove-element="(args) => $emit('removeElement', args)" />
        </template>
        <template v-else-if="element.type == 'select'">
            <AdminFormElementSelect :title="title" :index="index" :element="element" @remove-element="(args) => $emit('removeElement', args)" @select-changed="(args) => $emit('selectChanged', args)" />
        </template>
        <template v-else-if="element.type == 'section'">
            <AdminFormElementSection :iscollapsed="iscollapsed" :title="title" :index="index" :element="element" @remove-element="(args) => $emit('removeElement', args)" @select-changed="(args) => $emit('selectChanged', args)" @checkbox-changed="(args) => $emit('checkboxChanged', args)" />
        </template>
        <template v-else-if="element.type == 'table'">
            <AdminFormElementTable :title="title" :index="index" :element="element" @remove-element="(args) => $emit('removeElement', args)" />
        </template>
        <template v-else-if="element.type == 'checkbox'">
            <AdminFormElementCheckbox :title="title" :index="index" :element="element" @remove-element="(args) => $emit('removeElement', args)" @checkbox-changed="(args) => $emit('checkboxChanged', args)" />
        </template>
        <template v-else-if="element.type == 'radio'">
            <AdminFormElementRadio :title="title" :index="index" :element="element"  @remove-element="(args) => $emit('removeElement', args)" @checkbox-changed="(args) => $emit('checkboxChanged', args)" />
        </template>
        <template v-else-if="element.type == 'textarea'">
            <AdminFormElementTextarea :title="title" :index="index" :element="element" @remove-element="(args) => $emit('removeElement', args)" />
        </template>
        <template v-else-if="element.type == 'email'">
            <AdminFormElementEmail :title="title" :index="index" :element="element" @remove-element="(args) => $emit('removeElement', args)" />
        </template>
        <template v-else-if="element.type == 'tel'">
            <AdminFormElementTel :title="title" :index="index" :element="element" @remove-element="(args) => $emit('removeElement', args)" />
        </template>
        <template v-else-if="element.type == 'file'">
            <AdminFormElementFile :title="title" :index="index" :element="element" @remove-element="(args) => $emit('removeElement', args)" />
        </template>
        <template v-else-if="element.type == 'multi'">
            <AdminFormElementMulti :title="title" :index="index" :element="element" @remove-element="(args) => $emit('removeElement', args)" />
        </template>
        <template v-else-if="element.type == 'tableref'">
            <AdminFormElementTableref :title="title" :index="index" :element="element" @remove-element="(args) => $emit('removeElement', args)" />
        </template>
        <template v-else>
            <div>
                Field type <mark>{{element.type}}</mark> does not exist
            </div>
        </template>
    </div>
</template>
<script>
export default {
    name: 'AdminFormElement',
    mixins: [FormElementMixins],
    
    components: {
        AdminFormElementText,
        AdminFormElementNumber,
        AdminFormElementSelect,
        AdminFormElementSection,
        AdminFormElementTable,
        AdminFormElementCheckbox,
        AdminFormElementRadio,
        AdminFormElementTextarea,
        AdminFormElementEmail,
        AdminFormElementTel,
        AdminFormElementFile,
        AdminFormElementMulti,
        AdminFormElementTableref
    },
    methods: {
        
    }
}
</script>
<style lang="">
    
</style>