<script setup>
  import FormElementMixins from "@/mixins/FormElementMixins";
  // import ApiService from "@/services/ApiService";
  import { ref } from "vue";
  const files = ref([]);
</script>
<template lang="">
  <div>
    <div class="mb-3">
      <VueUploader class="uploader" :multiple="true" :label="element.title + requiredMark" v-model="files" @onFileUpload="handleFilesBeforeSave" @onRemoveFile="onRemoveFile"></VueUploader>
    </div>
  </div>
</template>
<script>
  export default {
    name: "FormElementFile",
    mixins: [FormElementMixins],
    data() {
      return {
        filepaths: [],
      }
    },
    computed: {
      formid() {
        return this.$route.params.formid;
      }
    },
    components: {},
    methods: {
      handleFilesBeforeSave(files) {
        this.filepaths = [...files.map((file) => `${this.formid}/${file.name}`)];
        this.element.files = this.filepaths;
        console.log('element', this.element.files)
        this.$emit("on-files-updated", files);
      },
      // onFileUpload(files) {
      //   // Filter out trash
      //   files = files.filter((file) => file.type.indexOf("image") == 0)
      //   if (files.length === 0) {
      //     return;
      //   }
      //   this.saveFiles(files);
      //   this.filepaths = [...files.map((file) => `${this.formid}/${file.name}`)];
      //   this.element.files = this.filepaths;
      // },
      onRemoveFile(files) {
        console.log(files);
      }
    },
  };
</script>
<style lang="scss">
@import "/scss/_colors.scss";
#drop-zone {
    max-height: 70px;
    > h3 {
        font-size: 1.5em;
    }
}
.uploader:has(.file-details) {
    #drop-zone {
        min-height: auto;
        padding: 0;
        > h3 {
            font-size: 100%;
        }   
    }
}
.file-details {
    justify-content: flex-start !important;
    column-gap: 10px;
    margin-top: 10px;
    .file {
        box-shadow: none;
        padding: 0;
        display: flex !important;
        align-items: center !important;
        gap: 5px;
        flex-wrap: wrap;
        border-radius: 4px;
        .file-name-content > p {
            margin: 0;
        }
        > .close-button {
            position: relative;
            top: auto;
            right: auto;
            color: $danger;
            font-weight: bold;
            border-radius: 50%;
            background-color: transparentize($color: $danger, $amount: 0.8);
        }
    }
}

</style>
