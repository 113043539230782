<script setup>
    import ApiService from '@/services/ApiService'
    import AdminFormAlert from '@/components/admin/AdminFormAlert.vue'
    
</script>
<template>
    <div class="container p-4 bg-lighter border border-top-0">
        <div class="row py-2" v-for="form in forms" :key="form.name">
            <div class="col">
                <router-link class="fs-5 text-dark fw-bold text-decoration-none" :to="`/admin/form/${form._id}`">{{form.title}}</router-link>
            </div>
            <div class="col-auto" v-if="!form.preferences.advancedEdits">
                <router-link target="_blank" class="btn btn-link text-primary fs-5" :to="`/${form._id}`">
                    <BIconLockFill></BIconLockFill>
                </router-link>
            </div>
            <div class="col-auto">
                <router-link target="_blank" class="btn btn-link text-primary fs-5" :to="`/${form._id}`">
                    <BIconBoxArrowUpRight></BIconBoxArrowUpRight>
                </router-link>
            </div>
            <div class="col-auto">
                <router-link class="btn btn-primary" :to="`/admin/form/${form._id}`">Edit</router-link>
            </div>
            <div class="col-auto">
                <button class="btn btn-outline-primary" @click="duplicateForm(form)">Duplikere</button>
            </div>
            <div class="col-auto" v-if="form.preferences.advancedEdits">
                <button class="btn btn-danger" @click="removeForm(form._id)">Fjerne</button>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row bg-light py-3 px-4">
            <div class="col-12 col-md-10">
                <AdminFormAlert :alert="alert" @on-alert-closed="$emit('onAlertCLosed')"></AdminFormAlert>
            </div>
            <div class="col-12 col-md-2 p-0">
                <button type="button" @click="createForm()" class="btn btn-primary d-block w-100" to="/admin/form/new">Opret formularer</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AdminFormsList',
        data() {
            return {
                forms: []
            }
        },
        methods: {
            
            fetchForms() {
                ApiService.getForms()
                .then(response => {
                    this.forms = response.data
                })
                .catch(error => {
                    console.log(error)
                })
            },
            createForm(form={title: 'Ny formular ' + new Date().toLocaleDateString(),elements: []}) {
                ApiService.createForm(form)
                .then(response => {
                    ApiService.handleResponse(response, { name: 'admin-form-edit', params: { formid: response.data._id } })
                })
            },
            duplicateForm(form) {
                this.createForm(form)
            },
            removeForm(id) {
                this.$confirm({
                    "title": "Fjerne formular",
                    "message": "Er du sikker på at du vil fjerne denne formular?",
                    button: {
                        no: "Nej",
                        yes: "Ja",
                    },
                    callback: confirm => {
                        if (confirm) {
                            ApiService.removeForm(id)
                            .then(response => {
                                ApiService.handleResponse(response)
                                this.fetchForms()
                            })
                        }
                    }
                })
            }
        },
        mounted() {
            this.fetchForms()
        }
    }
</script>

<style lang="scss" scoped>

</style>