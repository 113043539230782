!<template>
    <div v-if="alert" class="sticky-bottom">
        <div v-for="(a,i) in alert" :class="`alert alert-${a.variant || 'primary'} p-2`" role="alert" :ref="`alert-${i}`" :key="`alert-${i}`">
            {{ a.message }}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FormAlert',
        props: {
            alert: {
                type: Array,
                required: false
            }
        },
        methods: {
            
        },
        updated() {
            this.alert.forEach((a, i) => {
                setTimeout(() => {
                    this.$refs[`alert-${i}`][0].remove();
                }, a.duration || 2000);
            });
        },
    }
</script>

<style lang="scss" scoped>
    .alert {
        text-align: center;
    }
</style>