<script setup>
import AdminFormElement from '@/components/admin/AdminFormElement.vue'
import AdminFormAlert from '@/components/admin/AdminFormAlert.vue'
import AdminFormElementButtonAdd from '@/components/admin/elements/buttons/AdminFormElementButtonAdd.vue'
import AdminFormElementButtonRemove from '@/components/admin/elements/buttons/AdminFormElementButtonRemove.vue'
import _ from 'lodash'
</script>
<template lang="">
    <div class="container bg-lighter border border-top-0 px-4 py-4">
        <div class="row">
            <div class="col-12" v-if="!form.elements || form.elements.length == 0">
                <AdminFormElementButtonAdd wrapperClass="mt-3" btnClass="btn btn-primary fs-4 d-flex align-items-center px-2" :element="form.elements[0]" @add-element="addRootElement(0)"></AdminFormElementButtonAdd>
            </div>
            <div v-else :class="`col-${el.columns || 6} mb-5`" v-for="(el, index) in form.elements" :key="`content-element-${index}`">
                <div class="border-start border-5 border-primary-subtle">
                    <AdminFormElement
                        :iscollapsed="el.collapsed"
                        :element="el"
                        @checkbox-changed="(args) => $emit('checkboxChanged', args)"
                        @select-changed="(args) => $emit('selectChanged', args)"
                        />
                    <AdminFormElementButtonRemove v-if="advEdits" wrapperClass="mx-n4 mt-3" btnClass="btn btn-danger fs-4 d-flex align-items-center px-2" :element="el" @remove-element="removeRootElement(index)"></AdminFormElementButtonRemove>
                    <AdminFormElementButtonAdd v-if="advEdits" wrapperClass="mx-n4 mt-3" btnClass="btn btn-primary fs-4 d-flex align-items-center px-2" :element="element" @add-element="addRootElement(index)"></AdminFormElementButtonAdd>
                </div>
            </div>
        </div>
        <div class="row position-sticky sticky-bottom py-3">
            <div class="col-10">
                <AdminFormAlert :alert="alert" @on-alert-closed="$emit('onAlertCLosed')"></AdminFormAlert>
            </div>
            <div class="col-2">
                <button type="submit" class="btn btn-lg btn-primary py-2 d-block w-100">Spare</button>
            </div>
        </div>
    </div>
</template>
<script>
import { useAdminPreferences } from '@/composables/admin-preferences.js'
const { preferences } = useAdminPreferences()
export default {
    name: 'AdminFormContent',
    components: {
        AdminFormElement,
        AdminFormAlert,
        AdminFormElementButtonAdd,
        AdminFormElementButtonRemove
    },
    data() {
        return {
            sortedElements: this.form.elements
        }
    },
    computed: {
        advEdits() {
            return preferences.advancedEdits
        },
        allCollapsedSections() {
            const elements = this.form.elements;
            return _.flatMapDeep(elements, (element) => {
                if (element.collapsed === true) {
                    return element.id;
                } else if (element.elements) {
                    return _.flatMapDeep(element.elements, (subElement) => {
                        if (subElement.collapsed === true) {
                            return subElement.id;
                        } else {
                            return [];
                        }
                    });
                } else {
                    return [];
                }
            });
        }
    },
    props: {
        form: {
            type: Object,
            required: true
        },
        alert: {
            type: Object,
            required: false
        }
    },
    methods: {
        addRootElement(afterIndex) {
            this.$emit('addRootElement', afterIndex)
        },
        removeRootElement(atIndex) {
            this.$emit('removeRootElement', atIndex)
        }
    },
    created() {
        this.collapsedSections = this.allCollapsedSections
    },
}
</script>
<style lang="">
    
</style>.