<script setup>
    import { ref, onMounted } from 'vue'
    const loggedin = ref(false)
    
    onMounted(() => {
        if (localStorage.getItem('user') !== null) {
            loggedin.value = true
        }
    })
</script>

<template lang="">
    <div class="container bg-primary text-white px-5 py-4">
        <div class="d-flex align-items-center justify-content-between position-relative">
            <div class="w-100">
                <template v-if="form">
                    <textarea cols="" v-html="form.title" class="form-control fs-1 bg-primary text-white border-0 fw-medium mx-n2 p-0" style="height: 4em;" @input="onTitleChanged($event)"></textarea>
                    <div class="d-flex justify-content-between">
                        <router-link to="/admin/form" class="btn btn-sm btn-link text-white d-block fs-4 mx-n3 my-n2 p-1 position-absolute start-0 bottom-0 mx-n4 my-n4" >
                            <BIconChevronLeft class="me-2 mb-1 text-center align-center"></BIconChevronLeft>
                        </router-link>
                        <router-link target="_blank" class="btn btn-link text-white fs-5 p-0 m-0 d-block position-absolute top-0 mt-n5 mx-n4 end-0" :to="`/${form._id}`">
                            <BIconBoxArrowUpRight></BIconBoxArrowUpRight>
                        </router-link>
                    </div>
                </template>
                <slot></slot>
                <h5 v-if="!form">
                    Dato: {{ new Date().toLocaleDateString() }}
                </h5>
                <button v-if="loggedin" type="button" class="btn btn-sm btn-danger position-absolute bottom-0 end-0 mx-n4 my-n3" @click="onLogout">Logout</button>
            </div>
            <div class="ms-5">
                <img class="float-end" alt="Assa Abloy logo" src="@/assets/assa-logo-white.svg"> 
            </div>
        </div>
    </div>
</template>
<script>
export default {

    name: 'AdminFormHeader',
    props: {
        form: {
            type: Object,
            required: true
        }
    },
    methods: {
        onLogout() {
            localStorage.removeItem('user')
            this.$router.push({ name: 'user-login' })
        },
        onTitleChanged(event) {
            this.$emit('onTitleChanged', event.target.value)
        }   
    }
}
</script>
<style lang="scss" scope>
    textarea {
        line-height: 1.2em !important;
        width: 100% !important;
        height: 2.8em !important;
    }
</style>