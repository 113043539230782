<script setup>
import FormElementMixins from '@/mixins/FormElementMixins'
</script>
<template lang="">
    <div>
        <div class="mb-3">
            <label :for="`field${element.type}${index}`" class="form-label" v-if="fieldTitle">{{ fieldTitle }}</label>
            <input :data-section="element.section" :name="element.name" v-model="element.value" type="email" class="form-control" :id="`field${element.type}${index}`" :placeholder="element.placeholder" :required="element.required">
        </div>
    </div>
</template>
<script>
export default {
    name: 'FormElementEmail',
    mixins: [FormElementMixins]
}
</script>
<style lang="">
    
</style>