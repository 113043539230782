<template lang="">
    <div class="container bg-primary text-white px-3 py-3 px-md-5 py-md-4">
        <div class="d-flex align-items-center justify-content-between">
            <div class="w-auto">
                <h1 class="fs-1" v-if="form.title">
                    {{form.title}}
                </h1>
                <h5>
                    Dato: {{ new Date().toLocaleDateString() }}
                </h5>
            </div>
            <div class="ms-5 d-none d-md-block">
                <img class="float-end" alt="Assa Abloy logo" src="@/assets/assa-logo-white.svg"> 
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'FormHeader',
    props: {
        form: {
            type: Object,
            required: true
        }
    },
}
</script>
<style lang="">
    
</style>