import { createApp } from 'vue'
import App from './App.vue'
import vueUploader from "vue-uploader";
import "vue-uploader/dist/style.css";
import "bootstrap"
import Vue3ConfirmDialogBox from "vue3-confirm-dialog-box";
import router from './router'
import { BootstrapIconsPlugin } from "bootstrap-icons-vue";
import Popper from "vue3-popper";
import { VueReCaptcha } from 'vue-recaptcha-v3'
import axios from 'axios'
const app = createApp(App)
app.use(vueUploader);
app.use(router)
app.use(BootstrapIconsPlugin)
app.use(Vue3ConfirmDialogBox);
console.log('debug', router)
app.use(VueReCaptcha, { 
    siteKey: '6LeMKAkqAAAAAByDCZBegPkmnENqkDrbM1Dhc6d6',
    loaderOptions: {
        autoHideBadge: true
    }
})
app.component("vue3-confirm-dialog-box", Vue3ConfirmDialogBox.default);
app.component("Popper-X", Popper);
router.isReady().then(() => {
    app.mount('#app')
    axios.interceptors.response.use(
        response => response,
        error => {
            console.log('check')
          if (error.response.status === 401) {
            console.log('401')
          }
          return Promise.reject(error)
        }
      )
})
