<script setup>
import FormElementMixins from '@/mixins/FormElementMixins'
import AdminFormElementSelect from '@/components/admin/elements/AdminFormElementSelect.vue'
// import _ from 'lodash'
</script>
<template lang="">
    <div>
        <pre>{{element}}</pre>
        <div class="row my-4">
            <div class="col"><input type="text" v-model="element.title" placeholder="titel..." class="form-control fs-2 bg-light border-0 fw-medium mx-n2"></div>
            <div class="col-auto py-2"><input type="number" v-model="element.columns" placeholder="6" min="1" max="12" class="form-control font-monospace w-auto text-center"></div>
        </div>
        <div class="row mb-3">
            <div class="col-12">
                <textarea class="form-control" rows="3" placeholder="Description" v-model="element.body"></textarea>
            </div>
        </div>
        <!-- <pre>{{_.pick(element, ["title", "rows"])}}</pre> -->
        <table class="table table-bordered table-light">
            <thead class="table-primary">
                <tr>
                    <th scope="col" v-for="(header, colidx) in element.headers" :colspan="header.colspan" :key="`thead1-${colidx}`" class="py-0">
                        <!-- {{header.title}} -->
                        <input type="text" v-model="header.title" placeholder="" class="form-control fs-6 fw-bold bg-transparent text-white border-0 fw-medium mx-n2">
                    </th>
                </tr>
            </thead>
            <thead class="thead-names">
                <tr>
                    <th scope="col" v-for="(hcol, idx) in element.elements" class="py-0 px-2 m-0" :key="`thead2-${idx}`">
                        <input type="text" v-model="hcol.title" placeholder="" class="form-control bg-transparent border-0 mx-n2">
                    </th>
                </tr>
                <tr>
                    <th scope="col" v-for="(hcol, idx) in element.elements" class="py-0 px-0 m-0" :key="`thead2-${idx}`">
                        <Popper-X v-if="hcol.type == 'select'">
                            <button class="btn btn-white m-0 d-block">
                                <span class="me-4">select</span>
                                <BIconChevronDown class="small"></BIconChevronDown>
                            </button>
                            <template v-slot:content>
                                <AdminFormElementSelect :element="hcol"></AdminFormElementSelect>
                            </template>
                        </Popper-X>
                        <select v-else class="form-select border-0" v-model="hcol.type">
                            <option disabled>type</option>
                            <option value="text">text</option>
                            <option value="number">number</option>
                            <!-- <option value="select">select</option> -->
                        </select>
                    </th>
                </tr>
            </thead>
            <!-- <tbody>
                <template v-for="(row, i) in element.rows" :key="`row-${i}`">
                    <tr>
                        <td v-for="(col, index) in element.elements" :key="`col-${index}`">
                            <FormElement
                                :element="row[index]"
                                @select-changed="(args) => $emit('selectChanged', args)"
                                title=""
                                :index="i"
                                />
                        </td>
                        <td class="py-1">
                        </td>
                    </tr>
                </template>
            </tbody> -->
            
        </table>
    </div>
</template>
<script>
export default {
    name: 'AdminFormElementTable',
    mixins: [FormElementMixins],
    components: {
        AdminFormElementSelect
    },
    data() {
        return {
            rows: []
        }
    },
    methods: {
        
    },
    
}
</script>
<style lang="scss" scoped>
    @import '/scss/_colors';
    thead.table-primary th {
        background: $primary !important;
        color: #fff !important;
    }
    thead.thead-names th {
        font-size: 11px !important;
        font-weight: 300 !important;
        vertical-align: baseline;
    }
    tfoot button {
        border-radius: 0 !important;
    }
    .popper {

        min-width: 800px !important;
    }
</style>