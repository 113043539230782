<template>
    <div class="bg-primary position-absolute top-0 end-0 bottom-0 start-0 d-flex justify-content-center align-items-center h-100">
        <div class="loginbox p-4">
            <form @submit.prevent="login" class="">
                <div class="form-floating mb-2">
                    <input type="email" v-model="email" class="form-control" id="floatingInput" placeholder="name@example.com">
                    <label for="floatingInput">Email address</label>
                </div>
                <div class="form-floating mb-2">
                    <input type="password" v-model="password" class="form-control" id="floatingPassword" placeholder="Password">
                    <label for="floatingPassword">Password</label>
                </div>
                <button class="btn btn-primary bg-dark w-100 py-3" type="submit">Sign in</button>
            </form>
        </div>
    </div>
</template>

<script>
import ApiService from '@/services/ApiService';
export default {
    name: 'UserLogin',
    data() {
        return {
            email: '',
            password: ''
        }
    },
    methods: {
        async login(event) {
            event.preventDefault();
            ApiService.authUser(this.email, this.password)
            .then(response => {
                localStorage.setItem('user', JSON.stringify(response.data))
                this.$router.push({ name: 'admin-forms' })
            })
        }
    }
}
</script>

<style lang="scss">
    @import "/scss/_colors";

    .loginbox {
        position: absolute;
        width: 420px;
        max-width: 100vw;
        height: 190px;
        box-sizing: border-box;
        margin: 0 auto;
    }
</style>