import { createWebHistory, createRouter } from "vue-router";
import FormView from "./views/FormView.vue";
import SubmissionView from "./views/SubmissionView.vue";
// import HomeView from "./views/HomeView.vue";
import AdminFormEdit from "./views/Admin/AdminFormEdit.vue";
import AdminForms from "./views/Admin/AdminForms.vue";
import UserLogin from "./views/UserLogin.vue";

const routes = [
    // {
    //     path: "/",
    //     name: "Home",
    //     component: HomeView,
    // },
    {
      path: "/:formid",
      name: "Form",
      component: FormView
    },
    {
      path: "/:formid/:submissionid",
      name: "Submission",
      component: SubmissionView
    },
    {
      path: "/admin/form",
      name: "admin-forms",
      component: AdminForms,
      meta: { requiresAuth: true }
    },
    {
      path: "/admin/form/:formid",
      name: "admin-form-edit",
      component: AdminFormEdit,
      meta: { requiresAuth: true }
    },
    {
      path: "/",
      name: "user-login",
      component: UserLogin
    }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


router.beforeEach((to, from, next) => {
  const loggedInLocalStorage = JSON.parse(localStorage.getItem('user'));
  
  if (to.matched.some(record => record.meta.requiresAuth) && !loggedInLocalStorage) {
    localStorage.removeItem('user')
    next({
      name: 'user-login'
    })
  }
  next()
});

export default router;