<script setup>
    import FormHeader from '@/components/form/FormHeader.vue'
    import FormContent from '@/components/form/FormContent.vue'
    import FormFooter from '@/components/form/FormFooter.vue'
    import ApiService from '@/services/ApiService'
    import { toRaw } from 'vue'
    import _ from 'lodash'
</script>
<template lang="">
    <div v-if="form" class="container p-0">
        <form :name="form.name" :id="'form-'+form._id" :ref="form._id" method="post" action="" @submit.prevent="onSubmit" @change="onFormChanged" class="needs-validation" novalidate>
            <FormHeader :form="form"></FormHeader>
            <FormContent 
                :form="form" 
                @checkbox-changed="onCheckboxChanged"
                @radio-changed="onRadioChanged"
                @select-changed="onSelectChanged"
                @on-files-updated="handleFilesBeforeSave"
                :alert="alert"
                ></FormContent>
            <FormFooter></FormFooter>
        </form>
    </div>
</template>
<script>
    export default {
        name: 'FormView',
        components: {
            FormHeader,
            FormFooter,
            FormContent
        },
        data() {
            return {
                form: null,
                submission: null,
                alert: [],
                files: []
            }
        },
        // computed: {
        //     submissionData() {
                
        //         const elements = _.map(this.form.elements, (elem) => {
        //             if ( elem.type == "section" ) {
        //                 return {
        //                     title: elem.title,
        //                     type: elem.type,
        //                     elements: _.map(elem.elements, (subElem) => {
        //                         return {
        //                             title: subElem.title,
        //                             type: subElem.type,
        //                             value: subElem.value,
        //                             checked: subElem.checked,
        //                             selected: subElem.selected,
        //                             collapsed: subElem.collapsed,
        //                             elements: subElem.elements,
        //                             files: subElem.files
        //                         }
        //                     })
        //                 }
        //             }
        //             return {
        //                 title: elem.title,
        //                 type: elem.type,
        //                 value: elem.value,
        //                 checked: elem.checked,
        //             }
        //         })

        //         return {
        //             title: this.form.title,
        //             form: this.form.form,
        //             elements: elements
        //         }
        //     }
        // },
        methods: {
            handleFilesBeforeSave(files) {
                this.showAlert('success', 'File(s) was uploaded to the form. Now save!', 1000)
                this.files = files
            },
            onFormChanged() {
                // Populate submissionData with form data
                this.updateSubmissionData()
            },
            async updateSubmissionData() {
                // Populate submissionData with form data
                let subission = {..._.pick(this.form, ["_id", "title"])}
                subission.elements = await this.populateTablerefs(this.form.elements)
                this.submission = subission
            },
            showAlert(variant='warning', message, duration=2000) {
                this.alert.push({variant, message, duration})
            },
            async uploadFiles() {

                const files = this.files

                let formData = new FormData();
                    files.forEach((file) => {
                    formData.append("file", file);
                });
                
                try {
                    const response = await ApiService.uploadFiles(this.form._id, formData)
                    return response
                } catch (error) {
                    console.log(error)   
                    return error
                }

            },
            async submitForm() {
                try {
                    const response = await ApiService.submitForm({...this.submission, form: this.form._id})
                    console.log('response', response)
                    return response
                } catch (error) {
                    console.log(error)
                    return error
                    
                }
            },
            async onSubmit(event) {
                const form = event.target
                console.log('onSubmit')
                if (!form.checkValidity()) {
                    event.preventDefault()
                    event.stopPropagation()
                    form.classList.add('was-validated')
                    return false
                }

                try {
                    if ( this.files.length > 0 ) {
                        const upload = await this.uploadFiles()
                        this.showAlert(upload.data.variant || 'danger', upload.data.message, 3000)
                    }
                } catch (error) {
                    this.showAlert('danger', error.message, 3000)
                }

                try {
                    const submission = await this.submitForm()
                    console.log('submission done', submission)
                    this.showAlert(submission.data.variant || 'success', submission.data.message, 3000)
                    // this.submission = submission.data
                    console.log('send email to ID', submission.data.result._id)
                    const email = await ApiService.sendEmail(submission.data.result._id)
                    this.showAlert(email.data.variant || 'success', email.data.message, 3000)
                } catch (error) {
                    this.showAlert('danger', error.message, 3000)
                }

            },
            getSectionWithID(id) {

                let _flatten = _.flattenDeep(this.form.elements)
                return toRaw(_flatten.find(el => el.id === id))
            },
            getSectionWithSection(section) {

                let _flatten = _.flattenDeep(this.form.elements)
                return _flatten.find(el => el.section === section)
            },
            findNested(arr, section) {
                let found = arr.find(node => node.section === section)
                return found ? found.elements.length > 0 : arr.some((c) => this.findNested(c.elements, section))

            },
            disableElement(elems, section, disabled) {
                elems.forEach((el) => {
                    if ( el.section == section ) {
                        el.disabled = disabled
                    }
                    if (el.elements) {
                        this.disableElement(el.elements, section, disabled)
                    }
                })
            },
            expandChildren(elems, children, name, index) {
                // console.log('name', name)
                if ( name ) {
                    elems.forEach((el) => {
                        if ( el.rows && el.type == 'table' ) {
                            el.rows.forEach((row, j) => {
                                if ( j == index ) {
                                    row.map((col) => { // Collapse all related columns
                                        col.collapsed = col.ref == name
                                        if ( col.elements ) {
                                            col.elements = col.elements.map((subCol) => {
                                                // console.log('subcol', _.pick(subCol, ["name", "ref", "collapsed"]), subCol.ref == name , name)
                                                subCol.collapsed = subCol.ref == name
                                                return subCol
                                            })
                                            col.elements.forEach((subCol) => {
                                                if ( children.includes(subCol.id) ) {
                                                    subCol.collapsed = false
                                                }
                                            })
                                        }
                                        return col
                                    })
                                    row.forEach((col) => {
                                        if ( children.includes(col.id) ) {
                                            col.collapsed = false
                                        }
                                    })
                                }
                            })
                        }
                        if ( el.elements ) {
                            this.expandChildren(el.elements, children, name, index)
                        }
                    })
                }
            },
            checkRef(elems, section, name) {
                elems.forEach((el) => {
                    if ( (el.type == 'table' || el.type == 'section' || el.type == 'multi') && (el.ref && name &&  el.ref == name) ) {
                        console.log('id', el.id, 'section', section, 'name', name)
                        el.collapsed = el.id !== section
                    }
                    if (el.elements) {
                        this.checkRef(el.elements, section, name)
                    }
                })
            },
            setValue(elems, name, value) {
                elems.forEach((el) => {
                    if ( el.name == name ) {    
                        if ( el.type == 'radio' ) {
                            el.checked = el.value == value
                        } else {
                            el.value = value
                        }
                    }
                    if (el.elements) {
                        this.setValue(el.elements, name, value)
                    }
                })
            },
            onCheckboxChanged(el) {
                // Check for [set] object and set value of [set.name] to [set.value] recursively in elements
                if ( el.set && el.checked == true ) {
                    this.setValue(this.form.elements, el.set.name, el.set.value)
                }

                if ( el.disable && el.disable.length ) { // Array with objects describing which elements to disable
                    el.disable.map((disable) => {
                        this.disableElement(this.form.elements, disable.section, el.checked)
                    })

                }

                if ( el.section === undefined ) {
                    return
                }
                // Change collapse status of section with id = [el.id] in this.form.elements
                this.form.elements.map(elem => {
                    if (elem.id === el.section) {
                        elem.collapsed = !elem.collapsed
                    }
                    if ( elem.elements ) {
                        elem.elements.map(subElem => {
                            if (subElem.id === el.section) {
                                subElem.collapsed = !subElem.collapsed
                            }
                        })
                    }
                    return elem
                })
            },
            onRadioChanged(e) {
                const section = e.target.dataset.section
                const name = e.target.name
                if ( section === undefined ) {
                    return
                }
                const elements = this.form.elements
                this.checkRef(elements, section, name)

                if ( e.target.dataset.set === undefined ) {
                    return
                }

                const getSet = (set) => {
                    try {
                        return  JSON.parse(e.target.dataset.set)
                    } catch (error) {
                        console.log('Error parsing JSON', error, e.target.dataset.set)                    
                    }
                    return set
                }

                const set = getSet(e.target.dataset.set)

                this.setValue(elements, set.name, set.value)


                
            },
            onSelectChanged(e) {
                const elements = this.form.elements
                const name = e.target.name
                if ( e.target.options[e.target.selectedIndex].dataset.children ) { // Option have children
                    let children = JSON.parse(e.target.options[e.target.selectedIndex].dataset.children)
                    // let children = e.target.options[e.target.selectedIndex].dataset.children.split(',') || []
                    if ( e.target.options[e.target.selectedIndex].dataset.index ) {
                        var index = e.target.options[e.target.selectedIndex].dataset.index

                        children.map((child, i) => {
                            children[i] = child + '-' + e.target.options[e.target.selectedIndex].dataset.index
                        })
                    }

                    this.expandChildren(elements, children, name, index)

               
                        


                } else {
                    const section = e.target.options[e.target.selectedIndex].dataset.section
                    let name = e.target.name
                    console.log('section', section, 'name', name)
                    const isIndexed = !isNaN(e.target.name.substr(-1))
                    if ( isIndexed ) {
                        name = name.replace(/-\d+$/, '')
                    }
                    if ( section === undefined ) {
                        return
                    }
                    this.checkRef(elements, section, name)
                    
                }
            },
            populateTablerefs(elements) {
                elements.forEach((el) => {
                    if (el.type == 'tableref') {
                        ApiService.getTable(el.tid)
                        .then(response => {
                            el = _.merge(el, response.data)
                        })
                    }
                    if (el.elements) {
                        this.populateTablerefs(el.elements)
                    }
                })
                return elements
            },
            fetchForm(id) {
                ApiService.getForm(id)
                .then(response => {
                    const form = response.data
                    form.elements = this.populateTablerefs(form.elements)
                    this.form = form
                })
                .catch(error => {
                    console.log(error)
                })
            }  
        },
        mounted() {
            this.fetchForm(this.$route.params.formid)
        }
    }
</script>
<style lang="">

</style>