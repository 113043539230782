<script setup>
import FormElementMixins from '@/mixins/FormElementMixins'
import AdminFormElement from '@/components/admin/AdminFormElement.vue'
import AdminFormElementButtonAdd from './buttons/AdminFormElementButtonAdd.vue';
import _ from 'lodash'
</script>
<template lang="">
    <div>
        <!-- <mark>{{element.id}} iscollapsed: {{iscollapsed}}</mark> -->
        <!-- <mark class="position-absolute font-monospace px-2" v-if="element.id">{{element.id}}</mark> -->
            <fieldset :class="{
                'bg-light py-3 border': !hasSubSections && !element.class,
                //[element.class]: element.class
                }" class="px-4 mb-3 position-relative">
                <!-- <legend>{{ element.title }}</legend> -->
                
                <div class="form-check form-switch" v-if="advEdits">
                    <input class="form-check-input" v-model="element.collapsed" type="checkbox" :id="element.id" role="switch">
                    <label class="form-check-label" :for="element.id">Skjul fra start</label>
                </div>
                <div class="row mt-0 mb-3">
                    <div class="col"><input type="text" v-model="element.title" placeholder="titel..." class="form-control fs-2 bg-light-subtle border-0 fw-medium"></div>
                    <div class="col-auto py-2 px-0" v-if="advEdits"><input type="text" v-model="element.id" placeholder="" class="form-control form-control bs-highlight font-monospace"></div>
                    <div class="col-auto py-2" v-if="advEdits"><input type="number" v-model="element.columns" placeholder="6" min="1" max="12" class="form-control font-monospace w-auto text-center"></div>
                </div>
                <div class="row mb-3" v-if="advEdits">
                    <div class="col-12">
                        <textarea class="form-control bg-light-subtle border-0" rows="3" placeholder="Description" v-model="element.body"></textarea>
                    </div>
                </div>
                <div v-if="advEdits">
                    <small class="text-muted fw-bold">CSS classes</small>
                    <input type="text" class="form-control font-monospace bg-light form-control-sm mb-2" v-model="element.class">
                </div>
                <!-- <p v-if="element.body">{{element.body}}</p> -->
                <div class="row" v-if="elements">
                    <div :class="`col-${el.columns || 6}`" v-for="(el, index) in elements" :key="`${el.name}-${index}`">
                        <AdminFormElement :element="el" @remove-element="removeElement" />
                    </div>
                    <div class="col-6" v-if="advEdits">
                        <!-- <AdminFormElementButtonRemove :element="element" @remove-element="removeSection"></AdminFormElementButtonRemove> -->
                        <AdminFormElementButtonAdd :element="element" @add-element="addElement"></AdminFormElementButtonAdd>
                    </div>
                </div>
            </fieldset>
            <!-- <hr class="mb-4" v-if="hasSubSections"> -->
    </div>
</template>
<script>

export default {
    name: 'AdminFormElementSection',
    mixins: [FormElementMixins],
    components: {
        AdminFormElement,
        AdminFormElementButtonAdd,
        // AdminFormElementButtonRemove
    },
    computed: {
        elements() {
            return this.element.elements
        },
        hasSubSections() {
            return this.elements && this.elements.filter(element => element.type === 'section').length > 0
        },
        allCollapsedSections() {
            const elements = this.element.elements;
            return _.flatMapDeep(elements, (element) => {
                if (element.collapsed === true) {
                    return element.id;
                } else if (element.elements) {
                    return _.flatMapDeep(element.elements, (subElement) => {
                        if (subElement.collapsed === true) {
                            return subElement.id;
                        } else {
                            return [];
                        }
                    });
                } else {
                    return [];
                }
            });
        }
    },
    data() {
        return {
            collapsedSections: []
        }
    },
    methods: {
        addElement() {
            const timestamp = Math.floor(Date.now() / 1000)
            this.element.elements.push({
                title: '',
                name: 'element-' + timestamp,
                type: 'text',
                value: '',
                required: false,
                placeholder: ''
            })
        },
        removeElement(element) {
            console.log('REMOVE', element)
            this.element.elements = this.element.elements.filter(el => el !== element)
        },
        removeSection(element) {
            this.$emit('remove-element', element)
        }
    },
    created() {
        this.collapsedSections = this.allCollapsedSections
    },
}
</script>
<style lang="scss">
    
</style>