<template lang="">
    <div class="container pt-4 pb-5">
        <div class="row">
            <div class="text-center">
                <div class="fw-bold">OBS! Alle felter mærket * skal udfyldes.</div>
                <div><a class="fw-bold" href="mailto:system.dk.openingsolutions@assaabloy.com">Feedback</a></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'FormFooter'
}
</script>
<style lang="">
    
</style>