<script setup>
import FormElementMixins from '@/mixins/FormElementMixins'
import FormElement from '@/components/form/FormElement.vue'
import _ from 'lodash'
import { Collapse } from 'vue-collapsed'

</script>
<template lang="">
    <div>
        <!-- <mark>{{element.id}} iscollapsed: {{iscollapsed}}</mark> -->
        <Collapse :when="!iscollapsed">
            <fieldset :class="{
                'bg-light mb-4 px-4 py-3 border': !hasSubSections && !element.class,
                [element.class]: element.class
                }">
                <legend>{{ element.title }}</legend>
                <p v-if="element.body" v-html="element.body"></p>
                <div class="row" v-if="elements">
                    <div :class="{[`col-${el.columns || 6}`]: index === undefined}" v-for="(el, i) in elements" :key="`${el.name}-${i}`">
                        <FormElement 
                            :iscollapsed="el.collapsed" 
                            :element="el" 
                            :index="index"
                            @select-changed="(args) => $emit('selectChanged', args)" 
                            @checkbox-changed="(args) => $emit('checkboxChanged', args)" 
                            @radio-changed="(args) => $emit('radioChanged', args)" 
                            @on-files-updated="(args) => $emit('on-files-updated', args)"
                        />
                    </div>
                </div>
            </fieldset>
            <hr class="mb-4" v-if="hasSubSections">
        </Collapse>
    </div>
</template>
<script>
export default {
    name: 'FormElementSection',
    mixins: [FormElementMixins],
    components: {
        FormElement,
    },
    computed: {
        elements() {
            return this.element.elements
        },
        hasSubSections() {
            return this.elements && this.elements.filter(element => element.type === 'section').length > 0
        },
        allCollapsedSections() {
            const elements = this.element.elements;
            return _.flatMapDeep(elements, (element) => {
                if (element.collapsed === true) {
                    return element.id;
                } else if (element.elements) {
                    return _.flatMapDeep(element.elements, (subElement) => {
                        if (subElement.collapsed === true) {
                            return subElement.id;
                        } else {
                            return [];
                        }
                    });
                } else {
                    return [];
                }
            });
        }
    },
    data() {
        return {
            collapsedSections: []
        }
    },
    methods: {
        
    },
    created() {
        this.collapsedSections = this.allCollapsedSections
    },
}
</script>
<style lang="scss">
    
</style>