<script setup>
import FormElementMixins from '@/mixins/FormElementMixins'
</script>
<template lang="">
    <div>
        <div class="mb-3">
            <label :for="fieldId" class="form-label" v-if="fieldTitle">{{ fieldTitle }} 
                <Popper-X v-if="element.help" arrow>
                <span class="text-primary mx-2 fs-5">
                    <BIconQuestionSquareFill></BIconQuestionSquareFill>
                </span>
                <template v-slot:content>
                    <div v-html="element.help"></div>
                </template>
                </Popper-X>
            </label>
            <input @focus="element.confirm ? $confirm({...element.confirm, callback: confirmCallback}) : () => {}" :data-section="element.section" v-model="element.value" type="text" class="form-control" :id="fieldId" :key="fieldId" :placeholder="element.placeholder" :required="element.required" :disabled="element.disabled">
            <div class="form-text" v-if="element.description && element.collapsed !== true">
                {{element.description}}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'FormElementText',
    mixins: [FormElementMixins],
    methods: {
        confirmCallback(confirm) {
            if ( confirm ) {
                this.element.confirm = null
            }
        }
    },
}
</script>
<style lang="scss">
  
</style>