
<template lang="">
        <div class="container bg-lighter border border-top-0 px-5 py-4" v-if="elements">
        <div class="row">
            <div class="col-12" v-for="(section, index) in elements" :key="`section-${index}`">
                <hr v-if="section.title">
                <h4 class="text-primary">{{section.title}}</h4>
                <div class="row">
                    <div :class="`col-6`" v-for="(item, index) in section.elements" :key="`item-${index}`">
                        <div class="d-flex">
                            <div class="fw-bold w-50">{{item.title}}</div>
                            <div class="flex-fill">{{item.value}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>
<script>
export default {
    name: 'SubmissionContent',
    computed: {
        elements() {
            return this.submission.elements
        }
    },
    props: {
        submission: {
            type: Object,
            required: true
        }
    }
}
</script>
<style lang="">
    
</style>.