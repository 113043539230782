<script setup>
import FormElementMixins from '@/mixins/FormElementMixins'
import AdminFormElement from '@/components/admin/AdminFormElement.vue'
import AdminFormElementButtonAdd from './buttons/AdminFormElementButtonAdd.vue';
</script>
<template lang="">
    <div>
        <div class="">
            <small class="text-muted fw-bold">{{ element.type }}</small>
            <div class="container bg-secondary border py-2 px-1 gx-1">
                <div class="row">
                    <div class="col-12">
                        <input type="text" v-model="element.title" class="form-control">
                    </div>
                </div>
                <div class="row" v-if="element.elements">
                    <div :class="`col-${el.columns || 6}`" v-for="(el, index) in element.elements" :key="`${el.name}-${index}`">
                        <AdminFormElement :element="el" @remove-element="removeElement" />
                    </div>
                    <div class="col-auto" v-if="advEdits">
                        <AdminFormElementButtonAdd :element="element" @add-element="addElement"></AdminFormElementButtonAdd>
                    </div>
                </div>
            </div>
            <!-- <label :for="fieldId" class="form-label" v-if="fieldTitle">{{ fieldTitle }} 
            </label>
                
            <input :data-section="element.section" v-model="element.value" type="text" class="form-control" :id="fieldId" :key="fieldId" :placeholder="element.placeholder"> -->
        </div>
    </div>
</template>
<script>
export default {
    name: 'AdminFormElementMulti',
    components: {
        AdminFormElement
    },
    mixins: [FormElementMixins],
    methods: {
        removeElement(element) {
            console.log('REMOVE', element)
            this.element.elements = this.element.elements.filter(el => el !== element)
        }
    }
}
</script>
<style lang="scss">
    :root {
    --popper-theme-background-color: #F6F6F6;
    --popper-theme-background-color-hover: #F6F6F6;
    --popper-theme-text-color: #000;
    --popper-theme-border-width: 1px;
    --popper-theme-border-style: solid;
    --popper-theme-border-color: #D9D9D9;
    --popper-theme-border-radius: 4px;
    --popper-theme-padding: 24px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.05);
    
  }
</style>