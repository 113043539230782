<template>
  <vue3-confirm-dialog-box></vue3-confirm-dialog-box>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style lang="scss">
@import "scss/custom.scss";
</style>
